<template>
  <v-form
    class="public-page-form justify-center d-flex flex"
    @submit.prevent="submit">
    <v-card :flat="hasTwoPaneLayout">
      <v-card-text>
        <v-row class="mb-6">
          <v-col
            cols="12"
            class="text-center pb-0">
            <h2
              v-if="hasTwoPaneLayout"
              class="public-page-form__title">
              {{ $t('title') }}
            </h2>
            <router-link
              v-else
              :to="{ name: 'login' }">
              <CyAppPublicLogo class="public-page-form__logo"/>
            </router-link>
          </v-col>
          <v-col
            v-if="!hasTwoPaneLayout"
            cols="12"
            class="text-center py-0">
            <h5 class="public-page-form__subtitle">
              {{ $t('title') }}
            </h5>
          </v-col>
        </v-row>

        <v-row class="mx-6 mt-4">
          <v-col>
            <CyAlert
              theme="error"
              :content="resetPasswordErrors"/>
          </v-col>
        </v-row>
        <v-row class="mx-6 mt-4">
          <v-col>
            <v-text-field
              v-model="resetPassword.password"
              :label="$t('forms.fieldPassword')"
              :error-messages="passwordErrors"
              :append-icon="resetPassword.passwordVisibility ? 'visibility_off' : 'visibility'"
              :type="resetPassword.passwordVisibility ? 'text' : 'password'"
              required
              class="required-field"
              @click:append="() => $toggle.resetPassword.passwordVisibility()"
              @input="$v.resetPassword.password.$touch()"
              @blur="$v.resetPassword.password.$touch()"/>
          </v-col>
        </v-row>
        <CyPasswordStrength
          :password="resetPassword.password"
          class="password-strength"/>
        <v-row class="mx-6 mt-6">
          <v-col>
            <v-text-field
              v-model="resetPassword.confirmPassword"
              :label="$t('fieldConfirmPassword')"
              :error-messages="confirmPasswordErrors"
              :append-icon="resetPassword.confirmPasswordVisibility ? 'visibility_off' : 'visibility'"
              :type="resetPassword.confirmPasswordVisibility ? 'text' : 'password'"
              required
              class="required-field"
              @click:append="() => $toggle.resetPassword.confirmPasswordVisibility()"
              @input="$v.resetPassword.confirmPassword.$touch()"
              @blur="$v.resetPassword.confirmPassword.$touch()"/>
          </v-col>
        </v-row>
        <v-row class="mx-6 mt-4 mb-0 pb-0">
          <v-col
            cols="12"
            class="text-center pb-2">
            <CyButton
              :loading="loading"
              :disabled="$v.$invalid || loading"
              theme="success"
              type="submit">
              {{ $t('btnSaveNewPassword') }}
            </CyButton>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CyPasswordStrength from '@/components/password-strength.vue'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageResetPassword',
  components: {
    CyPasswordStrength,
  },
  props: {
    token: {
      type: String,
      default: '',
    },
  },
  validations: {
    resetPassword: {
      password: {
        required,
        minLength: minLength(8),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },
  data: () => ({
    resetPassword: {
      password: '',
      confirmPassword: '',
      passwordVisibility: false,
      confirmPasswordVisibility: false,
    },
    loading: false,
  }),
  computed: {
    ...mapState('auth', {
      resetPasswordErrors: (state) => state.errors.resetPassword,
    }),
    passwordErrors () {
      const errors = []
      const { $dirty, required, minLength } = this.$v.resetPassword.password
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!minLength) errors.push(this.$t('fieldPasswordMinLength'))
      return errors
    },
    confirmPasswordErrors () {
      const errors = []
      const { $dirty, sameAsPassword, required } = this.$v.resetPassword.confirmPassword
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!sameAsPassword) errors.push(this.$t('fieldConfirmPasswordSameAsPassword'))
      return errors
    },
    hasTwoPaneLayout () {
      return this.$route.meta?.layout?.isTwoPane
    },
  },
  methods: {
    ...mapActions('auth', [
      'RESET_PASSWORD',
    ]),
    async submit () {
      this.$v.$touch()
      this.loading = true

      await this.RESET_PASSWORD({ token: this.token, password: this.resetPassword.password })

      if (_.isEmpty(this.resetPasswordErrors)) this.$router.push({ name: 'login', query: { action: 'passwordReset' } })
      this.loading = false
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'Reset Password',
        btnSaveNewPassword: 'Save new password',
        fieldConfirmPassword: 'Confirm password',
        fieldConfirmPasswordSameAsPassword: 'It should be the same as the password',
        fieldPasswordMinLength: 'The password must have at least 8 characters',
      },
      es: {
        title: 'Cambiar contraseña',
        btnSaveNewPassword: 'Guardar nueva contraseña',
        fieldConfirmPassword: 'Confirmar contraseña',
        fieldConfirmPasswordSameAsPassword: 'La confimación debe ser idéntica a la contraseña',
        fieldPasswordMinLength: 'La contraseña debe tener al menos 8 caracteres',
      },
      fr: {
        title: 'Réinitialiser le mot de passe',
        btnSaveNewPassword: 'Enregistrer le nouveau mot de passe',
        fieldConfirmPassword: 'Confirmez le mot de passe',
        fieldConfirmPasswordSameAsPassword: 'La confirmation doit être identique au mot de passe',
        fieldPasswordMinLength: 'Le mot de passe doit contenir au moins 8 caractères',
      },
    },
  },
}
</script>
